<template>
  <div>
    <el-card>
      <el-row class="main">
        <p class="orderNumber">
          {{ $t("order.orderNumbers") }}{{ data.orderNumber }}
        </p>
        <!-- <el-col
          :span="6"
          v-for="(item, index) in data.productData"
          :key="index"
        >
          <img class="img fl" :src="item.img" />
          <h3 fl>
            {{ item.productName }}
          </h3>
          <p class="p1 fl" v-for="(size, s) in item.sizeData" :key="s">
            {{ size.color }}: {{ size.size }}
          </p>
        </el-col> -->
        <div class="clear"></div>
        <el-divider></el-divider>

        <div class="bieber">
          <p class="fl">{{ $t("order.name") }} {{ this.name }}</p>
          <p class="fl" v-if="this.email">
            {{ $t("order.mail") }}：{{ this.email }}
          </p>
          <p class="fl">{{ $t("order.contact") }}：{{ this.mobile }}</p>
          <p class="fl">{{ $t("order.detailedAddress") }} {{ this.addr4 }}</p>
          <p class="fl">{{ $t("order.select") }} {{ this.country }}</p>
          <div class="clear"></div>
          <div @click="edit" class="fl">
            <img src="@/assets/Vector_8.png" /><span>{{
              $t("order.modifyAddress")
            }}</span>
          </div>
          <!-- <img
            src="@/assets/Vector_9.png"
            @click="copy"
            v-clipboard="value"
            v-clipboard:success="handleSuccess"
            v-clipboard:error="handleError"
          /><span>{{ $t("order.copyAddress") }}</span> -->
          <div class="clear"></div>
        </div>
        <el-divider></el-divider>
        <!-- Logistics warehouse 物流仓库-->
        <h2 class="h2">{{ $t("order.logisticsWarehouse") }}</h2>
        <el-card>
          <div class="logistics fl">
            <!-- 物流名称 -->
            <h2 v-if="this.transportDeatils.logisticsName">
              {{ $t("order.logisticsname") }}：{{
                this.transportDeatils.logisticsName
              }}
            </h2>
            <h2 v-else>
              {{ $t("order.logisticsname") }}：{{
                this.logisticsForm.logisticsname
              }}
            </h2>
            <!-- 联系人姓名 -->
            <span v-if="this.transportDeatils.contackName"
              >{{ $t("order.contactname") }}:
              {{ this.transportDeatils.contackName }}</span
            >
            <span v-else
              >{{ $t("order.contactname") }}:
              {{ this.logisticsForm.contactname }}</span
            >
            <!-- 联系电话 -->
            <span v-if="this.transportDeatils.contackNumber"
              >{{ $t("order.receivingContactInformation") }}:
              {{ this.transportDeatils.contackNumber }}</span
            >
            <span v-else
              >{{ $t("order.receivingContactInformation") }}:
              {{ this.logisticsForm.contactnumber }}</span
            >
            <!-- 地址 -->
            <p v-if="this.transportDeatils.contackAddress">
              {{ $t("order.detailedAddress") }}
              {{ this.transportDeatils.contackAddress }}
            </p>
            <p v-else>
              {{ $t("order.detailedAddress") }} {{ this.logisticsForm.address }}
            </p>
            <!-- 备注 -->
            <p v-if="this.transportDeatils.remarks">
              {{ $t("order.remarks") }}: {{ this.transportDeatils.remarks }}
            </p>
            <p v-else>
              {{ $t("order.remarks") }}: {{ this.logisticsForm.remarks }}
            </p>
          </div>
          <el-button class="fr btn" @click="revise">{{
            $t("common.edit")
          }}</el-button>
          <div class="clear"></div>
        </el-card>
        <!-- 输入物流仓库 -->
        <el-collapse v-model="activeNames" @change="handleChange" v-show="show">
          <el-collapse-item
            :title="$t('order.inputLogisticsWarehouse')"
            name="0"
          >
            <!-- <h2>{{ $t("order.inputLogisticsWarehouse") }}</h2> -->
            <el-form
              :model="logisticsForm"
              :rules="rule"
              ref="logisticsForm"
              label-width="200px"
              class="form"
            >
              <div>
                <el-row>
                  <el-col :span="12" class="logisticsname fl">
                    <!-- Logistics name 物流名称-->
                    <el-form-item
                      :label="$t('order.logisticsname')"
                      prop="logisticsname"
                    >
                      <el-input
                        v-model="logisticsForm.logisticsname"
                        :placeholder="$t('order.logisticsnames')"
                      ></el-input>
                    </el-form-item>
                  </el-col>
                  <!-- Contact name 联系人姓名-->
                  <el-col :span="12" class="contactname fl">
                    <el-form-item
                      :label="$t('order.contactname')"
                      prop="contactname"
                    >
                      <el-input
                        v-model="logisticsForm.contactname"
                        :placeholder="$t('order.contactnames')"
                      ></el-input>
                    </el-form-item>
                    <div class="clear"></div>
                  </el-col>

                  <!-- Contact number 联系电话-->
                  <el-col :span="12" class="contactnumber fl">
                    <el-form-item
                      :label="$t('order.contactnumber')"
                      prop="contactnumber"
                    >
                      <el-input
                        v-model="logisticsForm.contactnumber"
                        :placeholder="$t('order.contactnumbers')"
                      ></el-input>
                    </el-form-item>
                  </el-col>
                  <!-- Address 地址-->
                  <el-col :span="12" class="address fl">
                    <el-form-item :label="$t('order.address')" prop="address">
                      <el-input
                        v-model="logisticsForm.address"
                        :placeholder="$t('order.addresss')"
                      ></el-input>
                    </el-form-item>
                  </el-col>
                </el-row>
                <!-- Remarks 备注-->
                <el-form-item :label="$t('order.remarks')" prop="remarks">
                  <el-input
                    v-model="logisticsForm.remarks"
                    type="textarea"
                    :placeholder="$t('order.remarkss')"
                    :autosize="{ minRows: 3, maxRows: 5 }"
                  ></el-input>
                </el-form-item>
                <h4>
                  {{ $t("order.logisticsWarehouses") }}
                  <el-button class="btn" @click="preservation">{{
                    $t("store.preservation")
                  }}</el-button>
                </h4>
              </div>
            </el-form>
          </el-collapse-item>
        </el-collapse>

        <el-form
          :model="ruleForm"
          :rules="rules"
          ref="ruleForm"
          label-width="200px"
          class="form"
        >
          <div class="bottom">
            <!-- Logistics order 物流订单-->
            <el-form-item
              :label="$t('order.logisticsorder')"
              prop="logisticsorder"
              class="logisticsorder"
            >
              
              <img :src="img" class="image" v-for="(img,index) in physical_images" :key="index" />

              <a class="btn_upload" @click="billOfLading">{{ $t("order.uploadLogisticsOrder") }}</a>
              <!-- <input
                type="file"
                ref="upload"
                style="display: none"
                accept="image/gif,image/jpeg,image/png,image/jpg"
                @change="freshImg"
              />
              <img
                src="@/assets/Group_1173.png"
                v-if="logoUrl == ''"
                @click="uploadImg"
                class="fl brand_logo"
              />
              <img
                :src="logoUrl"
                v-if="logoUrl != ''"
                @click="uploadImg"
                class="fl avatar"
              /> -->
            </el-form-item>
            <!-- Delivery method 送货方式-->
            <el-form-item
              prop="deliverymethod"
              :label="$t('order.deliverymethod')"
              class="deliverymethod"
            >
              <el-input
                v-model="ruleForm.deliverymethod"
                :placeholder="$t('order.deliverymethods')"
              ></el-input>
              <!-- <el-select
                type="text"
                class="select fl"
                v-model="ruleForm.deliverymethod"
                :placeholder="$t('order.deliverymethods')"
              >
                <el-option
                  v-for="item in categories"
                  :key="item._id"
                  :label="item.label"
                  :value="item._id"
                ></el-option>
              </el-select> -->
            </el-form-item>
            <!-- Export declaration 出口申报-->
            <el-form-item
              prop="exportdeclaration"
              :label="$t('order.exportdeclaration')"
              class="exportdeclaration"
            >
              <el-input
                v-model="ruleForm.exportdeclaration"
                :placeholder="$t('order.exportdeclarations')"
              ></el-input>
              <!-- <el-select
                type="text"
                class="select fl"
                v-model="ruleForm.exportdeclaration"
                :placeholder="$t('order.exportdeclarations')"
              >
                <el-option
                  v-for="item in categories"
                  :key="item._id"
                  :label="item.label"
                  :value="item._id"
                ></el-option>
              </el-select> -->
            </el-form-item>
            <!-- Waybill No 运单号-->
            <el-form-item
              :label="$t('order.waybill')"
              prop="waybill"
              class="waybill"
            >
              <el-input
                v-model="ruleForm.waybill"
                :placeholder="$t('order.waybills')"
              ></el-input>
            </el-form-item>
            <!-- Logisticscompany 物流公司-->
            <el-form-item
              prop="logisticscompany"
              :label="$t('order.logisticscompany')"
              class="logisticscompany"
            >
              <el-input
                v-model="ruleForm.logisticscompany"
                :placeholder="$t('order.logisticscompanys')"
              ></el-input>
              <!-- <el-select
                type="text"
                class="select fl"
                v-model="ruleForm.logisticscompany"
                :placeholder="$t('order.logisticscompanys')"
              >
                <el-option
                  v-for="item in categories"
                  :key="item._id"
                  :label="item.label"
                  :value="item._id"
                ></el-option>
              </el-select> -->
            </el-form-item>
            <!-- Shipping Address 送货地址-->
            <el-form-item
              prop="shipping"
              :label="$t('order.shipping')"
              class="shipping"
            >
              <el-input
                v-model="ruleForm.shipping"
                type="textarea"
                :placeholder="$t('order.shippings')"
                :autosize="{ minRows: 3, maxRows: 5 }" 
              />
            </el-form-item>
            <!-- Return address 退货地址-->
            <el-form-item
              prop="return"
              :label="$t('order.return')"
              class="return"
            >
              <el-input
                v-model="ruleForm.return"
                type="textarea"
                :placeholder="$t('order.returns')"
                :autosize="{ minRows: 3, maxRows: 4 }"
              />
            </el-form-item>
          </div>
        </el-form>
      </el-row>
    </el-card>
    <div class="bottom1">
      <el-button class="button1" @click="previousStep">{{
        $t("productManagement.previousStep")
      }}</el-button>
      <el-button class="button1" @click="deliverGoods">{{
        $t("order.deliverGoods")
      }}</el-button>
    </div>
    <!-- 编辑弹框 -->
    <el-dialog
      :title="$t('order.theReceivingAddress')"
      :visible="editDialog"
      @close="editDialog = false"
      width="50%"
    >
      <el-form
        label-width="15%"
        :model="addressForm"
        :rules="addressRules"
        ref="addressForm"
      >
        <!-- addressee 收件人 -->
        <el-form-item
          prop="addressee"
          :label="$t('order.addressee')"
          class="addressee"
        >
          <el-input
            class="brand_input"
            v-model="addressForm.addressee"
          ></el-input>
        </el-form-item>
        <!-- Select the country 选择国家-->
        <el-form-item
          prop="country"
          :label="$t('order.select')"
          class="selects"
        >
          <el-select
            type="text"
            class="select fl"
            v-model="addressForm.country"
            :placeholder="$t('order.country')"
          >
            <el-option
              v-for="item in select"
              :key="item._id"
              :label="$t('categorys.' + item.name)"
              :value="item.name"
            ></el-option>
          </el-select>

          <!-- <el-select
            class="select fl"
            v-model="province"
            :placeholder="$t('order.province')"
          >
            <el-option
              v-for="item in select"
              :key="item._id"
              :label="item.label"
              :value="item._id"
            ></el-option>
          </el-select>

          <el-select
            class="select fl"
            v-model="city"
            :placeholder="$t('order.city')"
          >
            <el-option
              v-for="item in select"
              :key="item._id"
              :label="item.label"
              :value="item._id"
            ></el-option>
          </el-select>
          <el-select
            class="select fl"
            v-model="area"
            :placeholder="$t('order.area')"
          >
            <el-option
              v-for="item in select"
              :key="item._id"
              :label="item.label"
              :value="item._id"
            ></el-option>
          </el-select> -->
        </el-form-item>
        <!-- Detailed address 详细地址-->
        <el-form-item
          prop="detailedAddress"
          :label="$t('order.detailedAddress')"
          class="detailedAddress"
        >
          <el-input v-model="addressForm.detailedAddress"></el-input>
        </el-form-item>
        <!-- Contact the phone number 联系电话 -->
        <el-form-item
          prop="contact"
          :label="$t('order.contact')"
          class="contact"
        >
          <el-input v-model="addressForm.contact"></el-input>
        </el-form-item>
        <!-- e-mail 电子邮件 -->
        <el-form-item prop="mail" :label="$t('order.mail')" class="mail">
          <el-input class="brand_input" v-model="addressForm.mail"></el-input>
        </el-form-item>
        <!-- 按钮 -->
        <div class="dialog-footer">
          <el-button @click="editDialog = false">{{
            $t("common.close")
          }}</el-button>
          <el-button type="primary" @click="done">{{
            $t("common.done")
          }}</el-button>
        </div>
      </el-form>
    </el-dialog>

    <el-dialog
      :title="$t('order.uploadLogisticsOrder')"
      :visible="dialogVisibles"
      width="35%"
      @close="dialogVisibles = false"
    >
      <div class="billOfLading">
        <div class="list" v-for="(img, index) in physical_images" :key="index">
          <i
            class="el-dialog__close el-icon el-icon-close"
            @click="deleteLading(index)"
          ></i>
          <img :src="img" class="fl avatar" />
        </div>
      </div>
      <div class="dialog-footer">
        <el-button type="primary" @click="swipersAddDialog = true">{{
          $t("common.add")
        }}</el-button>

        <el-button @click="dialogVisibles = false">{{
          $t("common.cancel")
        }}</el-button>
      </div>
    </el-dialog>

    <el-dialog
      :title="$t('common.add')"
      :visible="swipersAddDialog"
      @close="swipersAddDialog = false"
      width="35%"
    >
      <el-form ref="form" label-width="120px">
        <el-form-item :label="$t('shop.img')">
          <input
            type="file"
            id="swiperImageUpload"
            accept="image/gif,image/jpeg,image/png,image/jpg"
            @change="swiperImageUpload"
          />
        </el-form-item>
      </el-form>

      <div class="dialog-footer">
        <el-button @click="swipersAddDialog = false">{{
          $t("common.close")
        }}</el-button>
        <el-button type="primary">{{ $t("common.done") }}</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {
  createData,
  getStatus,
  getData,
  updateSetting,
} from "../../helper/api";
const moment = require("moment");
export default {
  data() {
    return {
      value: this.$refs["abc"],
      activeNames: ["0"],
      logoUrl: [],
      show: "",
      editDialog: false,
      dialogVisibles: false,
      swipersAddDialog: false,
      id: "",
      select: [],
      data: {},
      name: "",
      email: "",
      mobile: "",
      addr4: "",
      country: "",
      orderNumber: "",
      transportDeatils: {},
      physicals_id: '',
      ruleForm: {
        name: "",
        logisticsorder: [],
        pleaseselect: "",
        deliverymethod: "",
        exportdeclaration: "",
        waybill: "",
        logisticscompany: "",
        shipping: "",
        return: "",
      },
      rules: {},
      logisticsForm: {
        logisticsname: "",
        contactname: "",
        contactnumber: "",
        address: "",
        remarks: "",
      },
      rule: {
        logisticsname: [
          {
            required: true,
            message: this.$t("order.logisticsnames"),
            trigger: "blur",
          },
        ],
        contactname: [
          {
            required: true,
            message: this.$t("order.contactnames"),
            trigger: "blur",
          },
        ],
        contactnumber: [
          {
            required: true,
            message: this.$t("order.contactnumbers"),
            trigger: "blur",
          },
        ],
        address: [
          {
            required: true,
            message: this.$t("order.addresss"),
            trigger: "blur",
          },
        ],
      },
      addressForm: {
        addressee: "",
        country: "",
        detailedAddress: "",
        contact: "",
        mail: "",
      },
      addressRules: {
        addressee: [
          {
            required: true,
            message: this.$t("order.addresseereg"),
            trigger: "blur",
          },
        ],
        country: [
          {
            required: true,
            message: this.$t("order.selectreg"),
            trigger: "blur",
          },
        ],
        detailedAddress: [
          {
            required: true,
            message: this.$t("order.detailedAddressreg"),
            trigger: "blur",
          },
        ],
        contact: [
          {
            required: true,
            message: this.$t("order.contactreg"),
            trigger: "blur",
          },
        ],
        mail: [
          {
            required: true,
            message: this.$t("order.mailreg"),
            trigger: "blur",
          },
        ],
      },
      categories: [],
      physical_images: []
    };
  },
  created() {},
  mounted() {
    this.countrys();
    this.id = this.$route.query.id;
    this.getOrder(this.id);
  },

  methods: {
    deleteLading(id) {
      updateSetting("PUT", "order/delete-lading", {
        orderId: this.$route.query.id,
        ladingId: id,
      }).then();
      this.getOrder(this.$route.query.id);
    },
    swiperImageUpload(e) {
      let _this = this;
      _this.swiperObj = e.target.files["0"];
      let fr = new FileReader();
      fr.onload = function () {
        _this.swiperImage = fr.result;
        var image = _this.swiperImage.replace(/^data:image\/\w+;base64,/, "");

        _this.swipersAddDialog = false;

        createData("POST", "image/common", {
          dir: "physical_images",
          name:
            moment(new Date()).format("YYYYMMDDHHmmss") +
            Math.round(Math.random() * 99999),
          image: image,
        }).then((res) => {

            _this.physical_images.push(res.data.image);


          // _this.physical_image = res.data.image;
          // console.log(_this.logoUrl);

          // createData("PUT", "order/update-lading", {
          //   orderId: _this.$route.query.id,
          //   img: res.data.image,
          // }).then((res) => {
          //   console.log(res.message);
          //   if (res.message == "update successfully.") {
          //       _this.$message({
          //         message: _this.$t("common.updateSuccess"),
          //         type: "success",
          //       });
          //   } else {
          //       _this.$message({
          //         message: res.message,
          //         type: "success",
          //       });
          //   }
          //   _this.getOrder(_this.$route.query.id, _this.$route.query.status);
          // });

        });
      };
      fr.readAsDataURL(_this.swiperObj);
    },
    // 上传提单弹框
    billOfLading() {
      this.dialogVisibles = true;
    },
    // 数据
    getOrder(id) {
      getData("get", "order", id).then((res) => {
       // console.log(res, this.id);
        this.data = res.data;
        // this.productData = res.data.productData[0];
        this.orderNumber = res.data.orderNumber;
        this.shippingDeatils = res.data.shippingDeatils;
        this.name = this.shippingDeatils.titlename;
        this.email = this.shippingDeatils.email;
        this.mobile = this.shippingDeatils.mobile;
        this.addr4 = this.shippingDeatils.addr4;
        this.country = this.shippingDeatils.country;
        this.transportDeatils = res.data.transportDeatils;
        this.show = this.transportDeatils.contackName == "" ? true : false;

        console.log(this.data);
        this.addressForm = this.data.shippingDeatils;
        this.addressForm = {
            addressee: this.data.shippingDeatils.titlename,
            country: this.data.shippingDeatils.country,
            detailedAddress: this.data.shippingDeatils.addr4,
            contact: this.data.shippingDeatils.mobile,
            mail: this.data.shippingDeatils.email
        },

        //物流信息
        this.data.physicals.forEach((item, index) => {
            if (index == 0) {
                this.physicals_id = item.physical._id;
                this.physical_images = item.physical.img;
                this.ruleForm = {
                    name: item.physical.name,
                    pleaseselect: item.physical.pleaseselect,
                    deliverymethod: item.physical.deliveryMethod,
                    exportdeclaration: item.physical.exportDeclaration,
                    waybill: item.physical.waybilNo,
                    logisticscompany: item.physical.company,
                    shipping: item.physical.address,
                    return: item.physical.returnAddress,
                };
            }
        });

        //产品
        this.data.productData.forEach((item, index) => {
          var sizeData = [];
          item.cars.forEach((car) => {
            car.specs.forEach((spec) => {
              sizeData.push({
                color: car.name,
                size: spec.name,
                quantity: spec.num,
                price: spec.price,
              });
            });
          });
          this.data.productData[index]["sizeData"] = sizeData;
        });
      });
    },
    uploadImg() {
      this.$refs["upload"].click();
    },
    freshImg(e) {
      let _this = this;
      _this.imgObj = e.target.files["0"];
      let fr = new FileReader();
      fr.onload = function () {
        _this.logoUrl = fr.result;
        let logisticsorder = _this.logoUrl.replace(
          /^data:image\/\w+;base64,/,
          ""
        );

        createData("POST", "image/common", {
          dir: "brand_images",
          name:
            moment(new Date()).format("YYYYMMDDHHmmss") +
            Math.round(Math.random() * 99999),
          image: logisticsorder,
        }).then((res) => {
          _this.ruleForm.logisticsorder = res.data.image;
        });
      };
      fr.readAsDataURL(_this.imgObj); //将读取到的文件编码成Data URL
    },
    // 国家数据
    countrys() {
      getStatus("GET", "front/country").then((res) => {
        console.log(res);
        this.select = res.data;
      });
    },
    // 上一步
    previousStep() {
      this.$router.go(-1);
    },
    // 编辑
    edit() {
      this.editDialog = true;
    },
    // 复制
    // copy() {},
    // handleSuccess() {
    //   //成功
    //   console.log("success", this.value);
    // },
    // handleError() {
    //   //失败
    //   console.log("error", this.value);
    // },

    // 编辑数据
    done() {
      this.$refs["addressForm"].validate((valid) => {
        if (valid) {
          createData("PUT", "order/update-shipping", {
            orderId: this.id,
            shippingDeatils: {
              titlename: this.addressForm.addressee,
              country: this.addressForm.country,
              addr4: this.addressForm.detailedAddress,
              mobile: this.addressForm.contact,
              email: this.addressForm.mail,
            },
          }).then((res) => {
            if (res.success == true) {
                this.getOrder(this.$route.query.id);
                this.editDialog = false;
                this.$message({
                    message: this.$t('common.updateSuccess'),
                    type: 'success'
                });
            }
          });
        }
      });
    },
    // 交付货物
    deliverGoods() {
      this.$refs["ruleForm"].validate((valid) => {
        if (valid) {

          this.ruleForm.logisticsorder = this.physical_images;


          if (this.ruleForm.logisticsorder) {

              if (this.physicals_id) {
                  updateSetting("PUT", "physical", {
                    orderId: this.id,
                    id: this.physicals_id,
                    img: this.ruleForm.logisticsorder,
                    deliveryMethod: this.ruleForm.deliverymethod,
                    exportDeclaration: this.ruleForm.exportdeclaration,
                    waybilNo: this.ruleForm.waybill,
                    company: this.ruleForm.logisticscompany,
                    address: this.ruleForm.shipping,
                    returnAddress: this.ruleForm.return,
                  }).then((res) => {
                    if (res.success == true) {
                        //this.$router.push("/orders/commodity");
                        this.$message({
                              message: this.$t('common.updateSuccess'),
                              type: 'success'
                        });
                    }
                  });
              } else {
                  createData("POST", "physical", {
                    orderId: this.id,
                    img: this.ruleForm.logisticsorder,
                    deliveryMethod: this.ruleForm.deliverymethod,
                    exportDeclaration: this.ruleForm.exportdeclaration,
                    waybilNo: this.ruleForm.waybill,
                    company: this.ruleForm.logisticscompany,
                    address: this.ruleForm.shipping,
                    returnAddress: this.ruleForm.return,
                  }).then((res) => {
                    if (res.success == true) {
                        this.$message({
                              message: this.$t('common.updateSuccess'),
                              type: 'success'
                        });
                    }
                  });
              }

            
          } else {
            this.$message({
              message: this.$t("order.logisticsorders"),
              type: "error",
            });
          }
        }
      });
    },
    // 折叠
    handleChange(val) {
      console.log(val);
    },
    // 修改按钮
    revise() {
      this.show = true;
      this.activeNames = ["0"];
    },
    // 保存按钮
    preservation() {
      this.$refs["logisticsForm"].validate((valid) => {
        if (valid) {
          createData("PUT", "order/update-transport", {
            orderId: this.id,
            transportDeatils: {
              logisticsName: this.logisticsForm.logisticsname,
              contackName: this.logisticsForm.contactname,
              contackNumber: this.logisticsForm.contactnumber,
              contackAddress: this.logisticsForm.address,
              remarks: this.logisticsForm.remarks,
            },
          }).then((res) => {
            console.log(res);
            if (res.success == true) {
              this.$router.go(0);
            } else {
              this.$message({
                message: this.$t("order.failed"),
                type: "error",
              });
            }
          });
        }
      });
    },
  },
};
</script>

<style scoped>
.main {
  margin-left: 30px;
  font-size: 13px;
}
.orderNumber {
  margin-top: 30px;
  margin-bottom: 31px;
  font-size: 16px;
}
.img {
  width: 44px;
  height: 46px;
  border-radius: 3px;
  margin-right: 20px;
}
h3 {
  font-size: 15px;
  margin-bottom: 15px;
}
.p1 {
  color: gray;
  margin-top: -5px;
}
.bieber p {
  margin-bottom: 25px;
  margin-right: 20px;
  font-size: 15px;
}
.bieber img {
  width: 14px;
  height: 14px;
}
.bieber span {
  margin-right: 50px;
  margin-left: 5px;
}
h2 {
  margin-top: 27px;
}
.h2 {
  font-size: 20px;
  margin-bottom: 31px;
}
.logistics {
  margin-left: 10px;
  margin-top: 5px;
  margin-bottom: 5px;
}
.logistics h2 {
  font-size: 15px;
  margin-top: 0;
  margin-bottom: 15px;
}
.logistics span {
  margin-right: 33px;
}
.logistics p {
  margin-top: 13px;
}
.form {
  margin-top: 38px;
  margin-left: -80px;
}
h4 {
  margin-left: 200px;
  font-size: 10px;
  color: gray;
}
.divider {
  margin-left: 80px;
  margin-top: 53px;
  margin-bottom: 40px;
}
div .el-input__inner {
  width: 100%;
}
div .el-textarea__inner {
  font-family: "微软雅黑";
  padding-top: 10px;
}
/* .contactname .el-input__inner {
  width: 100%;
}
.contactnumber .el-input__inner{
  width: 100%;
} */
div .el-collapse-item__header {
  margin-top: 20px;
  font-weight: 700;
  font-size: 20px;
}
.logisticsorder img {
  width: 49px;
  height: 49px;
}
.deliverymethod .select {
  width: 100%;
}
.exportdeclaration .select {
  width: 100%;
}
.logisticscompany .select {
  width: 100%;
}
.bottom .el-input__inner {
  width: 100%;
}
.bottom .el-textarea__inner {
  width: 100%;
}
.selects .select {
  width: 100%;
}
.dialog-footer {
  text-align: right;
}
.bottom .image {
  margin-right: 10px;
}
.bottom1 {
  height: 60px;
  margin-top: 20px;
  text-align: center;
  background: #fff;
  margin-bottom: 20px;
  border-radius: 10px;
}
.bottom1 .button1 {
  width: 131px;
  height: 100%;
  margin-right: 41px;
  background: #ff0a0a;
  color: #fff;
  font-size: 15px;
  font-weight: 500;
  border: none;
  border-radius: 0%;
}
.btn {
  width: 100px;
  height: 40px;
  margin-left: 30%;
  background: #ff0a0a;
  color: #fff;
}
.btn_upload {
    width: auto;
    height: 40px;
    background-color: #ff0a0a;
    color: #ffffff;
    border-radius: 10px;
    padding: 5px 10px 5px 10px;
    cursor: pointer;
}
.logisticsorder p {
  color: red;
  font-size: 14px;
  margin-left: 10px;
  cursor: pointer;
}
.billOfLading {
  min-height: 150px;
  overflow: hidden;
  margin-bottom: 30px;
}
.billOfLading .list {
  position: relative;
  height: auto;
  width: 110px;
  float: left;
}
.billOfLading .list .el-icon-close {
  position: absolute;
  margin-right: 50px;
  background-color: red;
  border-radius: 50%;
  z-index: 10000;
  left: 90px;
  width: 20px;
  color: #ffffff;
  text-align: center;
  padding-top: 1px;
  cursor: pointer;
}
.billOfLading img {
  width: 100px;
  height: 100px;
  margin: 5px;
}
</style>